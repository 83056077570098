import { getCookie, setCookie } from './components/cookie';
import { ajaxDebug } from './components/ajax-debug-log';
// import qs from 'qs';
import 'jquery-validation/dist/jquery.validate';
import noUiSlider from 'nouislider/dist/nouislider';

jQuery(document).ready(($) => {
  let isFormSubmitted = localStorage.getItem('formSubmitted') ? true : false;
  // let isFormSubmitted = true;
  let workDaysSlider = {};
  let defaultWorkDays = 3;

  if ( isFormSubmitted ) {
    $('.calc-locked').hide();
  }

  if (localStorage.getItem('workDays')) {
    defaultWorkDays = localStorage.getItem('workDays');
  }

  if (localStorage.getItem('casesDay')) {
    $('#cases-day').val(localStorage.getItem('casesDay'));
  }

  if ( $('#work-days').length )  {
    const workDaysSelector = document.getElementById('work-days');
    workDaysSlider = noUiSlider.create(workDaysSelector, {
      start: defaultWorkDays,
      step: 1,
      tooltips: true,
      connect: true,
      format: {
        from: function (value) {
          return parseInt(value);
        },
        to: function (value) {
          return parseInt(value);
        },
      },
      range: {
        min: 2,
        max: 7,
      },
    });
  }

  //numbers only
  $('.js-num').keypress(function (event) {
    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
      event.preventDefault();
    }
  });

  $(document).on('click', '.js-toggle-signup-form', () => {
    $('.calc-signup-form').show();
    $('.calc-section2 .md-col2').addClass('is-active-calc');

    return false;
  });

  const calculateRevenue = casesDay => {
    return casesDay * 365 * 500;
  };

  const calculateEbitda = revenue => {
    return revenue * 0.2;
  };

  const calculateAnnualSalary = (revenue, workDays, production = 0.25) => {
    return revenue / 365 * workDays * 52 * production;
  };

  const calculateAnnualProfitShare = (ebitda, workDays, ownership = 0.1) => {
    return ebitda / 7 * workDays * ownership * 0.75;
  };

  const calculateAnnualIncome = (annualSalary, annualProfitShare) => {
    return annualSalary + annualProfitShare;
  };

  const calculatePotentialExitValue = (ebitda, ownership = 0.1) => {
    return ebitda * 15 * ownership;
  };

  const calculateRevenuePerVet = (revenue, workDays) => {
    return revenue / 7 * workDays;
  };

  const setTableValues = (workDays, casesDay) => {
    const revenue = calculateRevenue(casesDay);
    const ebitda = calculateEbitda(revenue);
    const annualSalary = calculateAnnualSalary(revenue, workDays);
    const annualProfitShare = calculateAnnualProfitShare(ebitda, workDays);
    const annualIncome = calculateAnnualIncome(annualSalary, annualProfitShare);
    const potentialExitValue = calculatePotentialExitValue(ebitda);
    const revenuePerVet = calculateRevenuePerVet(revenue, workDays);

    //format revenue to currency and remove decimals
    $('.js-revenue-val').text(revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }));
    $('.js-profit-val').text(ebitda.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }));
    $('.js-profit-share-val').text(annualProfitShare.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }));
    $('.js-income-val').text(annualIncome.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }));
    $('.js-salary-val').text(annualSalary.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }));
    $('.js-exit-val').text(potentialExitValue.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }));
    $('.js-revenue-vet-val').text(revenuePerVet.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }));
  }

  setTableValues(workDaysSlider.get(), $('#cases-day').val());

  $('#cases-day').on('change input', () => {
    const workDays = workDaysSlider.get();
    const casesDay = $('#cases-day').val();

    if ( isFormSubmitted ) {
      setTableValues(workDays, casesDay);
    }
  });

  workDaysSlider.on('update', () => {
    const workDays = workDaysSlider.get();
    const casesDay = $('#cases-day').val();

    if ( isFormSubmitted ) {
      setTableValues(workDays, casesDay);
    }
  });

  //avoid to set less than min value for input[type=number] fields
  $('input[type=number]').on('input change', function() {
    if (this.value * 1 < this.min * 1) {
      console.log('less than min');
      this.value = this.min;
    }
  });

  $('.js-calc-form').on('submit', e => {
    e.preventDefault();

    const that = $(e.currentTarget);
    const workDays = workDaysSlider.get();
    const casesDay = $('#cases-day').val();

    const revenue = calculateRevenue(casesDay);
    const ebitda = calculateEbitda(revenue);
    const annualSalary = calculateAnnualSalary(revenue, workDays);
    const annualProfitShare = calculateAnnualProfitShare(ebitda, workDays);
    const annualIncome = calculateAnnualIncome(annualSalary, annualProfitShare);
    const potentialExitValue = calculatePotentialExitValue(ebitda);
    const revenuePerVet = calculateRevenuePerVet(revenue, workDays);

    const hsListId = that.attr('data-id');
    const hutk = getCookie('hubspotutk');


    const fName = $('#f-name-2').val();
    const lName = $('#l-name-2').val();
    const uRole = $('.js-u-role').val();
    const phoneNum = $('.js-phone-number').val();
    const city = $('.js-city-name').val();
    const practiceName = $('#practice_name-2').val() ?? '';
    const acceptance = $('#acceptance-1-2').is(':checked') ? 'Yes' : '';
    const email = $('#email-2').val();

    that.find('button').addClass('is-loading').attr('disabled', 'true');

    let hsData = {
      "submittedAt": Date.now(),
      "fields": [
        {
          "name": "email",
          "value": email,
        },
        {
          "name": "firstname",
          "value": fName,
        },
        {
          "name": "lastname",
          "value": lName,
        },
        {
          "name": "your_role",
          "value": uRole || '',
        },
        {
          "name": "phone",
          "value": phoneNum,
        },
        {
          "name": "city",
          "value": city,
        },
        {
          "name": "company",
          "value": practiceName,
        },
        {
          "name": "i_agree_to_receive_marketing_materials",
          "value": acceptance,
        },
        {
          "name": "annual_revenue__calculator_",
          "value": revenue,
        },
        {
          "name": "annual_profit__ebitda_",
          "value": ebitda,
        },
        {
          "name": "revenue_per_vet",
          "value": revenuePerVet,
        },
        {
          "name": "production",
          "value": "25",
        },
        {
          "name": "annual_salary",
          "value": annualSalary,
        },
        {
          "name": "my_ownership",
          "value": "10",
        },
        {
          "name": "my_annual_profit_share",
          "value": annualProfitShare,
        },
        {
          "name": "my_potential_total_annual_income",
          "value": annualIncome,
        },
        {
          "name": "my_potential_exit_in_3_5_years",
          "value": potentialExitValue,
        },
      ],
      "context": {
        "pageUri": window.location.href,
        "pageName": $('head title').text(),
        "ipAddress": window.userIP ? window.userIP : undefined,
      },
    };

    if (hutk) {
      hsData.context.hutk = hutk;
    }

    $.when($.ajax({
      url: `https://api.hsforms.com/submissions/v3/integration/submit/20247445/${hsListId}`,
      method: 'post',
      contentType: 'application/json',
      data: JSON.stringify(hsData),
    }))
    .then(res => {
      console.log(res);
      localStorage.setItem('formSubmitted', true);
      localStorage.setItem('workDays', workDays);
      localStorage.setItem('casesDay', casesDay);
      setTableValues(workDays, casesDay);
      $('.calc-signup-form').hide();
      $('.calc-locked').hide();
      that.find('button').removeClass('is-loading').removeAttr('disabled');
      $('.calc-section2 .md-col2').removeClass('is-active-calc');
      isFormSubmitted = true;

      $.ajax({
        url: '/wp-json/marketing/v1/conversion-api',
        method: 'post',
        data: {
          email: email,
          f_name: fName,
          l_name: lName,
          phone_number: phoneNum,
          page_url: window.location.href,
          fordm_id: hsListId,
          city: city,
        },
        success: (res) => {
          console.log(res);
          const fbEventId = res.fb_event_id;
          const country = res.country;
          const zip = res.zip;
          const regionXhr = res.region;

          if (window.dataLayer) {
            window.dataLayer.push({
              'event': 'formSubmission',
              'form_id': hsListId,
              'email': email,
              'first_name': fName,
              'last_name': lName,
              'fb_event_id': fbEventId,
              'phone_number': phoneNum,
              'country': country,
              'zip': zip,
              'city': city,
              'region': regionXhr,
              'ip': window.userIP,
              'user_agent': navigator.userAgent,
            });
          }
        },
        error: (e) => {
          console.log(e);
        },
      });
    })
    .catch(e => {
      const errorMsg = `time: ${new Date().toUTCString()}, email: ${email}, ${JSON.stringify(e)}`;
      ajaxDebug(errorMsg);
      console.log(errorMsg);
      alert('There was an error submitting the form. Please try again or contact us directly.')
      that.find('button').removeClass('is-loading').removeAttr('disabled');
    });

    return false;
  });
});
